import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { urlApi } from "../../services/apirest";
import t1 from "../../img/12.png";
import t2 from "../../img/13.png";
import t3 from "../../img/14.png";
//var trimestre = localStorage.getItem('Trimestre'); 
class Menu extends React.Component {
    ircalificaciones(trimestre) {
        console.log(trimestre);

        localStorage.setItem("Trimestre", trimestre);

        this.props.navigate('/datoscalificaciones');
    }
    salir = () => {
        this.props.navigate('/');
    }
    render() {
        return (
            <section className="vh-50 py-3">
                <div>
                    <div className="container">
                        <div className="container table-responsive py-3">
                            <h3>Bienvenido {localStorage.getItem("est_nombres")}</h3>
                            <div className="text-center my-5 mb-1">
                            <img onClick={() => this.ircalificaciones("1t")}  src={t1}
                                    style={{ width: '175px'}} alt="t1" />
                            </div>
                            <div className="text-center my-2">
                            <img onClick={() => this.ircalificaciones("2t")} src={t2}
                                    style={{ width: '175px'}} alt="t2" />
                            </div>
                            <div className="text-center my-2">
                            <img onClick={() => this.ircalificaciones("3t")} src={t3}
                                    style={{ width: '175px'}} alt="t3" />
                            </div>
                        </div>
                        <div className="position-absolute top-30 end-0 translate-middle-y" style={{ marginTop: "25px", marginRight: "20px" }}>

                            <svg onClick={this.salir} xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 512 512">
                                <path fill="#ff0000" d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 
                    20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 
                    0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 
                    0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 
                    32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/>
                            </svg>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
function Navegacion(props) {
    let navigate = useNavigate();
    return <Menu {...props} navigate={navigate} />
}
export default Navegacion;