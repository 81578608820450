import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { urlApi } from "../../services/apirest.js";
//var trimestre = localStorage.getItem('Trimestre'); 
class DatosCalificaciones extends React.Component {
    state = {
        registros: []
    }

    num_a_letra = (valor) => {
        const {cuant_a_cual} = this.props;
        return cuant_a_cual(valor)
    }

    componentDidMount = () => {      
        
        let url = urlApi + "calificaciones.php?idestudiante=" + localStorage.getItem('idusuario') + "&trimestre=" + localStorage.getItem("Trimestre");
        axios
            .get(url)
            .then(response => {
                this.setState({
                    registros: response.data
                })
                
            })
    }
    salir = () => {
        this.props.navigate('/');
    }
    rejresar = () => {
        this.props.navigate('/menu');
    }

    render() {
        return (
            <section className="vh-50 py-5">
            <div>
                <div className="container">
                    <div className="position-absolute top-0 start-0 ">
                    <svg onClick={this.rejresar} xmlns="http://www.w3.org/2000/svg" height="35" width="35" viewBox="0 0 512 512">
                    <path fill="blue" d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM271 135c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-87 87 87 87c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L167 273c-9.4-9.4-9.4-24.6 0-33.9L271 135z"/></svg>
                    </div>
                    <center><h3 style={{ fontFamily: "cambria", fontStyle: "italic", fontSize: "23px" }}><b>Trimestre:</b> <h style={{ color: "red" }}>{localStorage.getItem('Trimestre')}</h></h3></center>
                    <div className="container table-responsive py-2">
                        <table className="table table-hover">
                            <thead>
                                <tr className="text text-sm-center"> 
                                    <th scope="col">Asignatura</th>
                                    <th scope="col">CAL1</th>
                                    <th scope="col">CAL2</th>
                                    <th scope="col">CAL3</th>
                                    <th scope="col">CAL4</th>
                                    <th scope="col">Promedio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.registros.map((value, index) => {
                                    return (
                                        <tr key={index} className="text text-sm-center">
                                            <td>{value.asi_nombre}</td>
                                            <td>{value.cal1}</td>
                                            <td>{value.cal2}</td>
                                            <td>{value.cal3}</td>
                                            <td>{value.cal4}</td>
                                            <td>{value.promedio}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        
                    </div>
                    <div className="position-absolute top-30 end-0 translate-middle-y" style={{ marginTop: "25px", marginRight: "20px"}}>
                    
                    <svg onClick={this.salir} xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 512 512">
                    <path fill="#ff0000" d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 
                    20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 
                    0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 
                    0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 
                    32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/>
                    </svg>
                    
                    </div>
                </div>
            </div>
            </section>
        );
    }
}
function Navegacion(props) {
    let navigate = useNavigate();
    return <DatosCalificaciones {...props} navigate={navigate} />
}
export default Navegacion;