import React from "react";
import "./assets/CSS/App.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login.jsx";
import Datos_Calificaciones from "./components/calificaciones/Datos_Calificaciones.jsx";
import Menu from "./components/calificaciones/Menu.jsx"

class App extends React.Component {


  render() {
    return (
      <React.Fragment>
        <div className="notificacion"></div>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/datoscalificaciones"
              element={<Datos_Calificaciones />}
            />
            <Route
              path="/menu"
              element={<Menu />}
            />
          </Routes>

        </Router>
      </React.Fragment>
    );
  }
}

export default App;
