import React from "react";
import "../assets/CSS/Login.css";
import { urlApi } from "../services/apirest.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../img/A.jpg";

class Login extends React.Component {
    state = {
        form: {
         //"usuario": "1315139129",
            "usuario": "",
            "contrasena": "",
            "tipo": "Estudiante"
        },
        error: false,
        errorMsg: ""
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    manejadorLogin = () => {
        let url = urlApi + "auth.php";
        axios.post(url, this.state.form)
            .then(response => {
                
                console.log(this.state.form.tipo)
                if (response.data.status === "ok") {
                    if (this.state.form.tipo === "Estudiante") {
                        localStorage.setItem("token", response.data.result.token);
                        localStorage.setItem("idusuario", response.data.result.idusuario);
                        localStorage.setItem("est_nombres", response.data.result.nombre_est);
                        this.props.navigate("/menu");
                    } else {
                        console.log('ERROR')
                    }
                    // window.location.href = "/dashboard";
                } else {
                    this.setState({
                        error: true,
                        errorMsg: response.data.result.error_msg
                    })

                }
            })
            .catch(error => {
                this.setState({
                    error: false,
                    errorMsg: "Error de conexion"
                })
            })
    }

    render() {
        return (
            <React.Fragment>
                <body>
                    <section className="vh-50 py-5">
                        <div className="container-fluid h-custom">
                            <div className="h-100 ">
                                <div>
                                    <form>
                                        {/* IMAGEN LOGO */}
                                        <div className="text-center my-5">
                                            <img src={logo}
                                                style={{ width: '175px' }} alt="logo" />
                                            {/* <h4  className="mt-2 mb-5 pb-1">BIENVENIDO</h4> */}
                                        </div>
                                        <div className="cajas">
                                            <input type="text" id="form3Example3" className="form-control form-control-lg" name="usuario"
                                                placeholder="Cédula" onChange={this.manejadorOnchange} maxLength={10}/>
                                        </div>
                                        <div className="cajas">
                                            <input type="password" id="form3Example4" className="form-control form-control-lg" name="contrasena"
                                                placeholder="Contraseña" onChange={this.manejadorOnchange} />
                                        </div>
                                        <div>
                                            <input hidden value={'Estudiante'} type="text" id="form3Example5" className="form-control form-control-lg" name="estudiante"/>
                                        </div>
                                        <div className="d-grid gap-2">
                                            <button type="button" className="btn btn-primary" onClick={this.manejadorLogin}><b>Iniciar sesión</b>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </button>
                                        </div>
                                    </form>
                                    {this.state.error === true &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMsg}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                </body>
            </React.Fragment>
        );
    }


}
function WithNavigate(props) {
    let navigate = useNavigate();
    return <Login {...props} navigate={navigate} />
}
export default WithNavigate;

